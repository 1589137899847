/* src/user/components/UpcomingEvents/UpcomingEvents.css */

.scheduled-courses-section {
  background-color: #e7e7e7;
  border-radius: 10px ;
  margin: 20px;
  padding: 20px;

}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-item {
  flex: 1 1 30%; 
  margin: 10px;
}

@media (max-width: 768px) {
  .filter-item {
    flex: 1 1 100%; 
  }
}
.MuiTableCell-root {
  text-align: inherit;  /* Text will follow table's text direction (ltr or rtl) */
}
/* Optional: additional styling */
