/* src/components/AdminLogin.css */

.login-container {
  height: 100vh; /* Full height of the viewport */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background: linear-gradient(to right, #191227,#271c3e,#433465, #dfdede,#ebebeb,#ffffff); /* Background gradient */
}

/* New class to apply color to the right side of the screen */


.login-paper {
  padding: 80px; /* Padding around the content */
  border-radius: 32px !important; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: box-shadow 0.3s ease-in !important; /* Smooth transition for box shadow */
  background-color: #fff; /* White background for the paper */
  width: 300px !important;
}

.login-paper:hover {
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.login-title {
  color: #322450; /* Main color for the title */
  margin-bottom: 24px !important; /* Space below title */
  font-size: 27px !important;
  font-weight: 400 !important;
}

.login-error {
  padding: 16px; /* Space below error message */
  color: #ff0000; /* Red color for error messages */
}

.login-input {
  border-radius: 16px !important; /* Rounded input fields */
  margin-bottom: 16px !important; /* Space between input fields */
  width: 100% !important;
}

.login-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #3fbedc; /* Outline color for input fields */
}

.login-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #3fbedc; /* Outline color on hover */
}

.login-button {
  border-radius: 8px; /* Rounded button */
  background-color: #3fbedc; /* Button color */
  color: #fff; /* White text on button */
  transition: background-color 0.3s, transform 0.2s; /* Button transition */
}

.login-button:hover {
  background-color: #322450; /* Darker color on hover */
  transform: scale(1.05); /* Slightly grow on hover */
}
