/* src/admin/components/ViewPlaces.css */

.view-places-container {
  margin: 20px;
  height: 90vh; /* Set a fixed height to make it scrollable */
  overflow-y: auto; /* Enables vertical scrolling */
  padding-right: 10px; /* Optional for padding inside the scroll area */
}
/* Optional: Custom scrollbar styling */
.view-places-container::-webkit-scrollbar {
  width: 8px;
}

.view-places-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.view-places-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
  
  .places-table {
    width: 100%;
    border-collapse: collapse;
  }
  .places-table th {
  background-color: #f2f2f2;
  text-align: left;
}

button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #4fa94d;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
  
  .places-table th, .places-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .edit-button, .delete-button {
    padding: 5px 10px;
    margin-right: 5px;
    border: none;
    cursor: pointer;
  }
  
  .edit-button {
    background-color: #007bff;
    color: white;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
  }
  
  .edit-form-container {
    margin-top: 20px;
  }
  
  .edit-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .submit-button {
    background-color: #28a745;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cancel-button {
    background-color: #6c757d;
    color: white;
    padding: 10px;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  