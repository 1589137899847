/* General banner container styles */
.banner-container {
  position: relative;
  width: 92%;
  overflow: hidden;
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.15);
}

/* Banner cards container */
.banner-cards {
  display: flex;
  width: 100%;
  height: 300px;
  position: relative;
}

/* Each banner card */
.banner-card {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.banner-card.active .banner-content h2,
.banner-card.active .banner-content p {
  opacity: 1;
  transform: translateX(0);
}
.banner-content h2 {
  font-size: 1.8rem;
  color: #ffffff;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 1s ease, opacity 1s ease;
  font-weight: bold;
  border-bottom: 2px solid #b79269; /* Change underline color to the secondary color */
  padding-bottom: 5px; /* Adds spacing between the text and underline */
}

/* Left 25% for text and button */
.banner-content {
  width: 25%;
  background-color: rgba(114, 29, 32, 0.85); /* Primary color with transparency */
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  box-sizing: border-box;
  transition: transform 1s ease, opacity 1s ease;
  border-radius: 10px 0 0 10px;
}

.banner-content h2 {
  font-size: 1.8rem;
  color: #ffffff;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 1s ease, opacity 1s ease;
  font-weight: bold;
}

.banner-content p {
  font-size: 1rem;
  color: #ffffff;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 1s ease, opacity 1s ease;
}

.open-modal-button {
  margin-top: 20px;
  background-color: #b79269; /* Secondary color */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.open-modal-button:hover {
  background-color: #8d5e4b; /* Darker shade of secondary color */
}

/* Right 75% for image */
.banner-image {
  width: 75%;
  background-size: cover;
  background-position: center;
}

/* Previous and Next buttons */
.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  background-color: rgba(114, 29, 32, 0.85); /* Primary color with transparency */
  color: #ffffff;
  border: none;
  font-size: 1.5rem;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s ease;
}

.prev-button:hover,
.next-button:hover {
  background-color: #8d5e4b; /* Darker shade of secondary color */
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}

/* Floating effect on hover */
.banner-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .banner-card {
    height: 200px;
  }

  .banner-content h2 {
    font-size: 1.5rem;
  }

  .banner-content p {
    font-size: 0.9rem;
  }

  .prev-button, .next-button {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .banner-card {
    flex-direction: column;
  }

  .banner-image {
    width: 100%;
    height: 150px;
  }

  .banner-content {
    width: 100%;
    padding: 10px;
  }

  .banner-content h2 {
    font-size: 1.2rem;
  }

  .banner-content p {
    font-size: 0.8rem;
  }

  .prev-button, .next-button {
    font-size: 1rem;
  }
}
