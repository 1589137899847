/* Gallery container */
.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns in a grid */
  gap: 20px; /* Space between images */
  padding: 20px;
  justify-items: center;
  background-color: #f9f9f9;
  perspective: 1000px; /* Perspective for 3D-like effect */
}

/* Center the title and description */
.gallery-header {
  grid-column: 1 / -1; /* Make the header span across all columns */
  text-align: center;
  margin-bottom: 20px;
}

.gallery-header h2 {
  font-size: 2rem;
  margin: 0;
  text-decoration: none; /* Ensure no text underline */
  border-bottom: none; /* If there's a border underline */
}

.gallery-header p {
  font-size: 1.2rem;
  color: #666;
  max-width: 800px;
  margin: 10px auto 0;
  line-height: 1.6;
}

/* Placeholder message */
.no-images {
  text-align: center;
  font-size: 1.5rem;
  color: #888;
  padding: 20px;
}

/* Each image wrapper */
.gallery-image-wrapper {
  position: relative;
  width: 250px; /* Adjust as needed */
  height: 180px; /* Adjust as needed */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: white;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.gallery-image-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
.gallery-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-image-wrapper:hover .gallery-overlay {
  opacity: 1;
}
/* Image styling */
.gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the container without distortion */
  border-radius: 10px;
  transition: transform 0.4s ease-in-out, filter 0.4s ease-in-out;
}

/* Hover effect: Dramatic enlargement and floating effect */


/* Hover effect: Enlarge and brighten the image */
.gallery-image-wrapper:hover .gallery-image {
  transform: scale(1.1); /* Enlarge the image slightly */
  filter: brightness(1.1); /* Brighten the image for better visual impact */
}

/* Responsive Design */
@media (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns for medium devices */
  }

  .gallery-image-wrapper:hover {
    transform: scale(1.05) translateY(-5px); /* Smaller scale on smaller screens */
  }
}

@media (max-width: 480px) {
  .gallery-container {
    grid-template-columns: 1fr; /* One column for mobile */
  }

  .gallery-image-wrapper:hover {
    transform: scale(1.03) translateY(-3px); /* Smaller scale for mobile */
  }
}

/* Lightbox header styles */
.lightbox-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent background for header */
  position: relative; /* Ensures position for child elements */
}

.close-button {
  background: transparent; /* No background */
  color: white; /* White color for contrast */
  border: none; /* Remove border */
  font-size: 1.5rem; /* Larger size for better touch */
  cursor: pointer; /* Pointer cursor */
  transition: color 0.3s ease; /* Smooth transition */
}

.close-button:hover {
  color: #ff4d4d; /* Change color on hover */
}

/* Overlay style */
.yet-another-react-lightbox__overlay {
  background: rgba(0, 0, 0, 0.9); /* Darker overlay for better focus */
  backdrop-filter: blur(5px); /* Slight blur for modern touch */
}

/* Lightbox transition styles */
.yet-another-react-lightbox__slide-enter {
  opacity: 0; /* Start invisible */
  transform: scale(0.9); /* Scale down */
}

.yet-another-react-lightbox__slide-enter-active {
  opacity: 1; /* Fade in */
  transform: scale(1); /* Scale to normal size */
  transition: opacity 0.3s, transform 0.3s; /* Smooth transition */
}

.yet-another-react-lightbox__slide-exit {
  opacity: 1; /* Start fully visible */
  transform: scale(1); /* Normal size */
}

.yet-another-react-lightbox__slide-exit-active {
  opacity: 0; /* Fade out */
  transform: scale(0.9); /* Scale down */
  transition: opacity 0.3s, transform 0.3s; /* Smooth transition */
}
