/* src/admin/components/AddPlace.css */

.add-place-container {
    background-color: #ffffff; /* White background for contrast */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 400px; /* Maximum width of the form */
    margin: auto; /* Centering the container */
    position: relative; /* To position loading indicator */
}

h2 {
    color: #322450; /* Main theme color */
    margin-bottom: 20px; /* Space below the heading */
}

.form-group {
    display: flex;
    flex-direction: column; /* Arrange label and input vertically */
}

label {
    font-weight: bold; /* Bold labels */
    color: #322450; /* Main theme color for labels */
    margin-bottom: 5px; /* Space below the label */
}

input {
    padding: 10px; /* Padding inside input fields */
    border: 1px solid #ddd; /* Light gray border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size for inputs */
    transition: border-color 0.3s; /* Smooth transition on focus */
}

input:focus {
    border-color: #3fbedc; /* Color on focus */
    outline: none; /* Remove outline */
}

/* Button styling */
.submit-button {
    background-color: #322450; /* Darker background color */
    color: #ffffff; /* White text color */
    padding: 12px 24px; /* Padding inside button */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    font-size: 18px; /* Font size */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition on hover */
    margin-top: 20px; /* Space above the button */
}

.submit-button:hover {
    background-color: #3fbedc; /* Lighten color on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Loading Indicator Style */
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
.loading-indicator {
    position: absolute; /* Position it absolutely within the container */
    top: 50%; /* Center it vertically */
    left: 50%; /* Center it horizontally */
    transform: translate(-50%, -50%); /* Adjust for centering */
    color: #3fbedc; /* Match the main theme color */
    font-size: 18px; /* Larger font size for visibility */
    font-weight: bold; /* Bold text for emphasis */
    display: flex; /* Flexbox for centering */
    align-items: center; /* Center content vertically */
}

/* Loading Spinner */
.loading-indicator::before {
    content: ''; /* Empty content for spinner */
    width: 20px; /* Spinner size */
    height: 20px; /* Spinner size */
    border: 3px solid #3fbedc; /* Spinner color */
    border-top: 3px solid transparent; /* Spinner top color */
    border-radius: 50%; /* Circular spinner */
    animation: spin 1s linear infinite; /* Spin animation */
    margin-right: 10px; /* Space between spinner and text */
}
.add-place-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
/* Spin Animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
