.add-scheduled-course-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 90vh;
    overflow-y: auto;
    font-family: 'Roboto', sans-serif;
  }
  
  .add-scheduled-course-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
  
  .add-scheduled-course-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  .form-group select,
  .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.2s ease-in-out;
  }
  
  .form-group select:focus,
  .form-group input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  button[type='submit'] {
    width: 100%;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  button[type='submit']:hover {
    background-color: #0056b3;
  }
  
  button[disabled] {
    background-color: #999;
    cursor: not-allowed;
  }
  
  /* Loader Spinner alignment */
  .form-group button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Snackbar */
  .MuiAlert-root {
    font-size: 14px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .add-scheduled-course-container {
      padding: 15px;
    }
  }
  /* AddScheduledCourse.css */

/* Container for Add and View components */
.add-scheduled-course-container,
.view-all-banners-container {
    padding: 20px; /* Padding for both components */
    background-color: #fff; /* White background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Form styling */
.add-scheduled-course-form {
    display: flex;
    flex-direction: column; /* Stack form elements */
}

/* Individual form groups */
.form-group {
    margin-bottom: 20px; /* Space between form groups */
}

/* Button styling */
button {
    background-color: #007bff; /* Bootstrap primary color */
    color: #fff; /* White text */
    padding: 10px; /* Padding for buttons */
    border: none; /* Remove default border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s; /* Transition for hover effect */
}

button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* List styling for view all */
ul {
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
}

/* Styling for individual list items */
li {
    margin: 10px 0; /* Space between list items */
    display: flex;
    justify-content: space-between; /* Space between name and button */
}

  