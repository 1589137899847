.category-container {
  margin: 20px auto;
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  width: 96%;
  overflow: hidden;
  padding: 20px;
  border-radius: 10px;
  background-color: #fcfcfc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.category-item {
  /* display: flex; */
  flex-direction: column; /* Ensure the title is below the image */
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s;
  border-radius: 7px;
  padding: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.category-item:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.193); /* Shadow on hover */
  background-color: rgb(250, 250, 250); /* Light background */
}

.category-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255); /* Dark overlay */
  opacity: 0;
  transition: opacity 0.5s;
  z-index: -1; /* Ensure it's behind content */
}
.category-header{
  
}
.category-item:hover::before {
  opacity: 1; /* Dark overlay on hover */
}

.category-circle {
  width: 50px;
  height: 50px;
  border-radius: 25%;
  background-color: #f5f5f5;
  background-size: contain;
  transition: transform 0.4s ease;
  margin: 0 auto ;
}

.category-circle:hover {
  transform: rotate(360deg); /* Full rotation on hover */
}

.category-title {
  margin-top: 15px; /* Space between image and title */
  font-size: 10px;
  font-weight: bold;
  color: #333;
  padding: 12px;
  text-align: center;
  transition: color 0.3s ease;
}

.category-item:hover .category-title {
  color: #000000; /* Change color on hover */
  font-weight: bold;
}

/* Clear Filter Button */
.clear-filter-button {
  background-color: #721d20 !important;
  border: 1px solid #ccc !important;
  padding: 8px 15px !important;
  border-radius: 5px !important;
  font-size: 14px;
  color: #ffffff !important;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-top: 20px;
}

.clear-filter-button:hover {
  background-color: #b79269 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1) !important;
}
.category-item.selected {
  transform: scale(1.1); /* Slightly enlarge */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.193); /* Shadow effect */
  background-color: rgb(250, 250, 250); /* Light background */
}

.category-item.selected .category-circle {
  transform: rotate(360deg); /* Rotate on selection */
}

.category-item.selected .category-title {
  color: #000000; /* Change color on selection */
  font-weight: bold;
}

/* Mobile and Tablet Responsiveness */
@media (max-width: 768px) {
  .category-circle {
    width: 70px;
    height: 70px;
  }

  .category-title {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .category-circle {
    width: 60px;
    height: 60px;
  }

  .category-title {
    font-size: 11px;
  }
}
