/* src/user/components/Courses/Courses.css */

.courses-container {
  margin: 20px; /* Margin around the courses container */
  background-color: #ffffff;
  padding: 20px;
  width: 95%;
  border-radius: 10px;
}

.course-card {
  position: relative; /* Allow positioning of content */
  border-radius: 10px; /* Rounded corners */
  color: white; /* Default text color */
  cursor: pointer; /* Pointer cursor on hover */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  overflow: hidden; /* Hide overflowing content */
  height: 250px !important; /* Fixed height for all cards */
}

.course-card:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Add shadow on hover */
}

/* Styling for load more button container */
.load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .course-card {
    height: 200px; /* Smaller height on larger screens */
  }
}

@media (max-width: 480px) {
  .course-card {
    height: 150px; /* Smaller height for mobile screens */
  }
}
