/* src/admin/components/AddCourse.css */

.add-course-container {
    background-color: #ffffff; /* White background for contrast */
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 800px; /* Maximum width of the form */
    margin: auto; /* Centering the container */
    max-height: 90vh; /* Limit height to 90% of viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  h2 {
    color: #322450; /* Main theme color */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  .add-course-form {
    display: grid; /* Using grid layout */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive columns */
    gap: 15px; /* Space between items */
  }
  
  .form-group {
    display: flex;
    flex-direction: column; /* Arrange label and input vertically */
  }
  
  
  label {
    font-weight: bold; /* Bold labels */
    color: #322450; /* Main theme color for labels */
    margin-bottom: 5px; /* Space below the label */
  }
  
  input,
  select,
  textarea {
    padding: 10px; /* Padding inside input fields */
    border: 1px solid #ddd; /* Light gray border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size for inputs */
    transition: border-color 0.3s; /* Smooth transition on focus */
  }
  
  input:focus,
  select:focus,
  textarea:focus {
    border-color: #3fbedc; /* Color on focus */
    outline: none; /* Remove outline */
  }
  
  textarea {
    resize: vertical; /* Allow vertical resizing */
    min-height: 100px; /* Minimum height for textareas */
  }
  
  /* Updated button styling */
  .submit-button {
    background-color: #322450; /* Darker background color */
    color: #ffffff; /* White text color */
    padding: 12px 24px; /* Padding inside button */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    font-size: 18px; /* Font size */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition on hover */
    margin-top: 20px; /* Space above the button */
  }
  
  .submit-button:hover {
    background-color: #3fbedc; /* Lighten color on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  