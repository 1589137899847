/* General Footer Styling */
.footer {
  padding: 40px 20px;
  /* background-color: #f5f5f5; */
  background: linear-gradient(to right, #721d20, #b79269, #ffffff);

  color: #333333;
  text-align: center;
  border-top: 2px solid #721d20;
}

/* Content container with flexible layout */
.footer-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

/* Left-align Sajaya Academy section */
.footer-left {
  flex: 10;
  text-align: left;
}

.footer-left h3 {
  font-size: 1.8rem;
  color: #721d20;
  margin-bottom: 15px;
  text-align: center;
}

.footer-description p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555555;
  text-align: left;
  max-width: 600px;
  margin: 0;
}

/* Left-align Contact Us section */
.contact-card {
  flex: 2;
  text-align: left;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; Align content to the left within this div */
}

.contact-card h3 {
  font-size: 1.6rem;
  color: #721d20;
  margin-bottom: 10px;
  text-align: left;
}

.contact-info {
  text-align: left;
}

.contact-info p {
  margin: 10px 0;
  font-size: 0.95rem;
  color: #555555;
  text-align: left;
}

.contact-info a {
  color: #721d20;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #b79269;
}

/* Social Links */
.social-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.social-links a {
  font-size: 1.8rem;
  color: #333333;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-links a:hover {
  color: #721d20;
  transform: translateY(-5px);
}

/* Footer Bottom Text */
.footer p {
  font-size: 0.9rem;
  color: #888888;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }

  .footer-left {
    text-align: center;
  }

  .contact-card {
    text-align: center;
    padding: 0;
    align-items: center; /* Center-align content in smaller view */
  }
}

@media (max-width: 480px) {
  .footer-left h3,
  .contact-card h3 {
    font-size: 1.4rem;
  }

  .footer-description p,
  .contact-info p {
    font-size: 0.9rem;
  }

  .social-links a {
    font-size: 1.5rem;
  }
}
