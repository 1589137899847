/* Sidebar.css */

.sidebar {
    width: 250px; /* Fixed width for the sidebar */
    background-color: #f4f4f4; /* Light grey background */
    padding: 20px; /* Padding inside the sidebar */
    border-right: 2px solid #ddd; /* Light border on the right */
    height: 100vh; /* Full height of the viewport */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack items vertically */
    overflow-y: auto;
  }
  
  .sidebar h3 {
    text-align: center; /* Center the title */
    margin-bottom: 20px; /* Space below the title */
    color: #333; /* Dark color for the title */
  }
  
  .sidebar ul {
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
  }
  
  .sidebar li {
    margin: 15px 0; /* Space between items */
  }
  
  .sidebar li a {
    text-decoration: none; /* Remove underline from links */
    color: #007bff; /* Bootstrap primary color */
    font-size: 16px; /* Font size for links */
    padding: 10px; /* Padding for links */
    border-radius: 4px; /* Rounded corners */
    display: block; /* Make the whole area clickable */
    transition: background-color 0.3s, color 0.3s; /* Transition effects */
  }
  
  .sidebar li a:hover {
    background-color: #e9ecef !important; /* Light grey on hover */
    color: #0056b3 !important; /* Darker blue on hover */
  }

  