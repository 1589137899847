/* Gallery.css */
.view-gallery-container {
    max-width: 800px; /* Set a max width for the container */
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff; /* White background for a clean look */
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 80vh; /* Limit height to enable scrolling */
  }
  
  h2 {
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: #333; /* Darker text for better readability */
    margin-bottom: 20px;
    border-bottom: 2px solid #007bff; /* Bottom border for visual separation */
    padding-bottom: 10px;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */
    gap: 15px; /* Space between grid items */
  }
  
  .gallery-image-card {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s; /* Smooth scale effect */
  }
  
  .gallery-image-card:hover {
    transform: scale(1.05); /* Scale effect on hover */
  }
  
  .gallery-image-card img {
    width: 100%;
    height: 150px; /* Fixed height for the images */
    object-fit: cover; /* Maintain aspect ratio */
  }
  
  .gallery-image-actions {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background for action buttons */
    border-top: 1px solid #e0e0e0; /* Top border for separation */
  }
  
  .edit-button,
  .delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s; /* Smooth color transition */
  }
  
  .edit-button {
    background-color: #007bff; /* Blue background for edit */
    color: white;
  }
  
  .edit-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .delete-button {
    background-color: #dc3545; /* Red background for delete */
    color: white;
  }
  
  .delete-button:hover {
    background-color: #c82333; /* Darker red on hover */
  }
  .add-gallery-container {
    margin: 20px;
    text-align: center;
  }
  
  .add-gallery-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .add-gallery-container input[type="file"] {
    margin: 10px 0;
  }
  
  .add-gallery-container button {
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #3f51b5; /* Material Design Primary Color */
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .add-gallery-container button:hover {
    background-color: #303f9f; /* Darker shade for hover effect */
  }
  