/* App.css */

/* Global Styles */
body {
  font-family: 'Arial', sans-serif; /* Clean font */
  margin: 0;
  padding: 0;
  background-color: #dfdede; /* Light background for contrast */
  color: #322450; /* Main color for body text */
}

/* Admin Container Styles */
.admin-container {
  display: flex; /* Flex layout for sidebar and content */
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Prevent overflow */
}

/* Sidebar Styles */
.sidebar {
  width: 250px; /* Fixed width for the sidebar */
  background-color: #322450; /* Main color for sidebar */
  color: #f5f5f5; /* Light color for sidebar text */
  padding: 20px; /* Padding inside the sidebar */
  transition: width 0.3s ease; /* Smooth width transition */
}

.sidebar h3 {
  text-align: center; /* Center the title */
  margin-bottom: 20px; /* Space below the title */
  color: #3fbedc; /* Accent color for the title */
}

.sidebar ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
}

.sidebar li {
  margin: 15px 0; /* Space between items */
}

.sidebar li a {
  text-decoration: none; /* Remove underline from links */
  color: #f5f5f5; /* Light color for links to ensure readability */
  font-size: 16px; /* Font size for links */
  padding: 10px; /* Padding for links */
  border-radius: 4px; /* Rounded corners */
  display: block; /* Make the whole area clickable */
  transition: background-color 0.3s, color 0.3s; /* Transition effects */
}

.sidebar li a:hover {
  background-color: rgba(63, 190, 220, 0.2); /* Light hover effect */
  color: #322450; /* Change color on hover for visibility */
}

/* Content Styles */
.content {
  flex: 1; /* Take remaining space */
  padding: 20px; /* Padding for content */
  transition: background-color 0.3s; /* Smooth background transition */
  color: #322450; /* Ensure content text matches main color */
}

/* Animation for content */
.content-enter {
  opacity: 0;
  transform: translateY(20px);
}
.content-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.3s; /* Smooth transition */
}
.content-exit {
  opacity: 1;
  transform: translateY(0);
}
.content-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s, transform 0.3s; /* Smooth transition */
}

/* Additional styles for buttons */
button {
  background-color: #3fbedc; /* Accent color for buttons */
  color: #ffffff; /* White text for buttons */
  border: none; /* No border */
  padding: 10px 20px; /* Padding for button */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s, transform 0.2s; /* Transition effects */
}

button:hover {
  background-color: #322450; /* Darker background on hover */
  transform: translateY(-2px); /* Slight lift effect */
}
